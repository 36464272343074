<script setup>
    import { defineProps } from 'vue';

    const props = defineProps({
        data : Object,
    })
</script>

<script>
    
    export default {
        name : 'MediaContainer'
    }

</script>

<template>

    <RouterLink class="md:w-2/3
                        bg-slate-700 text-slate-50
                        hover:bg-slate-600 hover:text-slate-50
                        h-16 w-full
                        rounded-lg
                        m-auto
                        flex items-center"
                :to="{ path : props.data.url }"
                v-bind:target="props.data.newTab ? '_blank' : null"
                >
        <div class="m-auto">
            <p>{{ props.data.title }}</p>
        </div>
    </RouterLink>


</template>

<style>
</style>