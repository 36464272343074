<template>
  <div class="w-full flex flex-col justify-center items-center min-h-screen my-auto bg-slate-300">
    <!-- <app-header></app-header> -->
      <router-view></router-view>
    <!-- <app-footer></app-footer> -->
  </div>
</template>

<script setup>
  // vue components
  // import { ref, computed } from 'vue'

  // components
  // import AppHeader from './components/App/AppHeader.vue'
  // import AppResource from './components/App/Resource/AppResource.vue';
  // import AppFooter from './components/App/AppFooter.vue'

  // import { createRouter } from 'vue-router';

  // import { RouterLink, RouterView } from 'vue-router'

//   import AppHome from './components/App/Home/AppHome.vue'
//   import AppResource from './components/App/Resource/AppResource.vue'

//   const currentPath = ref(window.location.hash)

//   window.addEventListener('hashchange', () => {
//     currentPath.value = window.location.hash
//   })

//   const routes = [
//   { path : '/', component: AppHome },
//   { path : '/resource', component: AppResource },
// ]

//   const currentView = computed(() => {
//     return routes[currentPath.value.slice(1) || '/'] || AppHome
//   })

</script>

<script>
export default {
  name: 'App',
  components: {
    // AppHeader,
    // AppResource,
    // AppFooter
  },
}
</script>

<style>
</style>
