<script>

    export default {
        name: 'AppHero'
    }

</script>

<template>

    <div class="container w-full p-4 h-48
    rounded-lg
    flex flex-column items-center
    bg-slate-700 text-neutral-50">
        <div class="flex flex-col items-center w-full">
            <div>
                <span class="text-2xl font-bold">WELCOME TO THE</span>
            </div>
            <div>
                <span class="text-3xl font-bold">SCI-PI COMMUNITY</span>
            </div>
        </div>
    </div>

</template>