<script>
    export default {
        name: 'ToHome'
    }
</script>


<template>
    <RouterLink :to="{ name: 'home' }">
        <button class="bg-slate-700 text-slate-50 rounded-lg py-2 px-4">
            <span>
                <i class="fa-solid fa-circle-chevron-left"></i> Back
            </span>
        </button>
    </RouterLink>
</template>

<style></style>