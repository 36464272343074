<script setup>
    import MediaContainer from '../Links/MediaContainer.vue'
    import AppHero from './AppHero.vue'
    import { ref } from 'vue'
    const socials = ref([
        {
            'platform' : 'facebook',
            'logo' : 'fa-brands fa-facebook',
            'url' : 'https://www.facebook.com/scipitutor',
        },
        {
            'platform' : 'instagram',
            'logo' : 'fa-brands fa-instagram',
            'url' : 'https://www.instagram.com/scipitutor',
        },
        {
            'platform' : 'youtube',
            'logo' : 'fa-brands fa-youtube',
            'url' : 'https://www.youtube.com/@scipitutor',
        }
    ])

    
    const resources = ref([
        {
            'id' : 'scipi-calculus',
            'title' : 'Sci-Pi Calculus',
            'url' : 'scipicalculus',
            'newTab' : false,
        },
        {
            'id' : 'scholars-support-hour',
            'title' : 'Scholars\'s Support Hour',
            'url' : 'events/ssh',
            'newTab' : true,
        }
    ])
</script>


<template>
    <main class="w-full md:w-1/2 w-5/6">

        <section class="w-11/12 m-auto mb-10 space-y-8" id="home-explore-subjects">

            <app-hero class="mb-8"></app-hero>
            <h2 class="text-center font-bold text-2xl mb-8">Explore</h2>
            
            <div class="flex flex-col justify-start items-center space-y-4">
                <media-container v-for="(data, index) in resources" :data="data" :key="index"></media-container>
            </div>

            <div>
                <div class="text-center mb-4">
                    <span>Follow Us</span>
                </div>
                <div class="w-100 flex flex-row justify-center items-center space-x-4">
                    <div v-for="(item, index) in socials" :key="index">
                        <a :href="item.url" target="_blank">
                            <span>
                                <i :class="item.logo" class="text-2xl"></i>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
            
        </section>
    </main>
</template>

<style>
</style>