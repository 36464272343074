import { createWebHistory, createRouter } from 'vue-router'

import AppHome from './components/App/Home/AppHome.vue'
import ScipiCalculus from './components/ScipiCalculus.vue'

const routes = [
  { path : '/', name: 'home', component: AppHome, meta: {
    title: 'Home'
  } },
  { path : '/scipicalculus', name : 'scipicalculus.index', component: ScipiCalculus, meta: {
    title: 'Sci-Pi Calculus'
  } },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Default Title';
  next();
});

export default router