<script setup>
    import ToHome from './Button/ToHome.vue'
    import MediaContainer from './App/Links/MediaContainer.vue'

    import { ref } from 'vue'

    const resources = ref([
        {
            'id' : 'open-book',
            'title' : 'The Open Book',
            'url' : '/scipicalculus/open-book',
            'newTab' : true,
        }
    ])

    const socials = ref([
        {
            'platform' : 'facebook',
            'logo' : 'fa-brands fa-facebook',
            'url' : 'https://www.facebook.com/scipicalculus',
        }
    ])
</script>

<script>
    
    export default {
        name : 'ScipiCalculus'
    }

</script>

<template>

    <main class="w-full md:w-1/2 w-5/6">
        <section class="w-11/12 m-auto mb-10 space-y-8" id="home-explore-subjects">

            <to-home></to-home>
            
            <h2 class="text-center font-bold text-2xl">Explore</h2>

            <div class="flex flex-col justify-start items-center space-y-4">
                <media-container v-for="(data, index) in resources" :data="data" :key="index"></media-container>
            </div>

            <div>
                <div class="text-center mb-4">
                    <span>Follow Us</span>
                </div>
                <div class="w-100 flex flex-row justify-center items-center space-x-2">
                    <div v-for="(item, index) in socials" :key="index">
                        <a :href="item.url" target="_blank">
                            <span>
                                <i :class="item.logo" class="text-2xl"></i>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
            
            
        </section>
    </main>


</template>

<style>
</style>